import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import {
  RiskAssessmentQuestionnaireHandler
} from 'src/pages/RiskAssessmentQuestionnairePage/RiskAssessmentQuestionnaireHandler/RiskAssessmentQuestionnaireHandler';
import QuestionnaireGeneralInfo from 'src/pages/QuestionnairesPage/QuestionnaireGeneralInfo/QuestionnaireGeneralInfo';
import {
  useGeneralFamilyPedigreeInfo,
  useGetGeneralQuestionnaireInfo,
  completedAssessmentHandler,
  useReturnToLastQuestionMutation
} from 'src/hooks/api/useRiskAssessmentQuestionaire';
import { useActiveTab } from 'src/hooks/useActiveTab';
import { QuestionnaireStatuses, QuestionnairesTypes } from 'src/constans/constans';
import { QuestionnairesPageWrapper } from 'src/pages/QuestionnairesPage/QuestionnairesPage.styles';
import { FamilyTreeSettingsInterface } from 'src/models/RiskAssessmentQuestionnaire';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';
import { openLinkInNewTab } from 'src/utils/openLinkInNewTab'

interface Props {
  isWidgetActiveTab: boolean
  botTypePreference: string
  familyTreeSettings: FamilyTreeSettingsInterface
  setFamilyTreeSettings: Dispatch<SetStateAction<FamilyTreeSettingsInterface>>
}

export const QuestionnairesPage: FC<Props> = ({
  isWidgetActiveTab,
  botTypePreference,
  familyTreeSettings,
  setFamilyTreeSettings
}) => {
  const chatId = localStorage.getItem('chatId')
  const { activeTab } = useActiveTab()
  const { riskAssessmentData, reFetchRiskAssessmentData } = useGetGeneralQuestionnaireInfo(chatId);
  const { familyPedigreeData, reFetchFamilyPedigreeData } = useGeneralFamilyPedigreeInfo(chatId);
  const { mutate: returnRequest, isLoading } = useReturnToLastQuestionMutation(() => setCurrentPage(QuestionnairesTypes.FAMILY_PEDIGREE));
  const [currentPage, setCurrentPage] = useLocalStorage('currentQuestionnairePage', QuestionnairesTypes.ALL_QUESTIONNAIRES);
  const currentQuestionnaireStatus = currentPage === QuestionnairesTypes.RISK_ASSESSMENT ? riskAssessmentData?.status : familyPedigreeData?.status
  const showRiskAssessmentMainButton = () => {
    const isRACompleted = riskAssessmentData?.status === QuestionnaireStatuses.COMPLETED
    const isFPCompleted = familyPedigreeData?.status === QuestionnaireStatuses.COMPLETED
    return isRACompleted ? (isRACompleted && isFPCompleted) : true
  }
  const { speak } = useProcessingTextToVoiceByType(botTypePreference)

  useEffect(() => {
    reFetchRiskAssessmentData()
    reFetchFamilyPedigreeData()
  }, [currentPage, activeTab, reFetchFamilyPedigreeData, reFetchRiskAssessmentData])

  const onClickHandler = (questionnaireName: QuestionnairesTypes) => {
    if (currentQuestionnaireStatus === QuestionnaireStatuses.COMPLETED && questionnaireName === QuestionnairesTypes.FAMILY_PEDIGREE) {
      return openLinkInNewTab(chatId)
    }
    speak({ text: 'i', volume: 0 })

    setCurrentPage(questionnaireName)
  }

  const editAnswersHandler = () => {
    if (isLoading) return
    returnRequest({ chat_id: chatId })
  }

  return (
    <QuestionnairesPageWrapper isWidgetActiveTab={isWidgetActiveTab}>
      {currentPage === QuestionnairesTypes.ALL_QUESTIONNAIRES ? (
        <>{riskAssessmentData && <QuestionnaireGeneralInfo
          title={'Risk Assessment'}
          onClickHandler={() => onClickHandler(QuestionnairesTypes.RISK_ASSESSMENT) }
          completedAssessmentHandler={() => completedAssessmentHandler(chatId, QuestionnairesTypes.RISK_ASSESSMENT)}
          answeredBlocks={riskAssessmentData?.questionnaire_progress?.answered_blocks}
          totalBlocks={riskAssessmentData?.questionnaire_progress?.total_blocks}
          questionnaireStatus={riskAssessmentData?.status}
          questionnaireType={QuestionnairesTypes.RISK_ASSESSMENT}
          isShowRAMainButton={showRiskAssessmentMainButton()}
          familyTreeSettings={familyTreeSettings}
        />}
        {familyPedigreeData && <QuestionnaireGeneralInfo
          title={'Biological Family\'s Cancer History'}
          onClickHandler={() => onClickHandler(QuestionnairesTypes.FAMILY_PEDIGREE)}
          completedAssessmentHandler={() => completedAssessmentHandler(chatId, QuestionnairesTypes.FAMILY_PEDIGREE)}
          answeredBlocks={familyPedigreeData?.questionnaire_progress?.answered_blocks}
          totalBlocks={familyPedigreeData?.questionnaire_progress?.total_blocks}
          questionnaireStatus={familyPedigreeData?.status}
          questionnaireType={QuestionnairesTypes.FAMILY_PEDIGREE}
          editMyAnswersHandler={editAnswersHandler}
          isEditMyAnswersButtonDisable={isLoading}
          hasFamilyTree={true}
          familyTreeSettings={familyTreeSettings}
        />}
        </>
      ) :
        <RiskAssessmentQuestionnaireHandler
          currentPage={currentPage}
          currentQuestionnaireStatus={currentQuestionnaireStatus}
          botTypePreference={botTypePreference}
          familyTreeSettings={familyTreeSettings}
          setFamilyTreeSettings={setFamilyTreeSettings}
        />
      }
    </QuestionnairesPageWrapper>
  );
};
