import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import {
  MICROPHONE,
  MICROPHONE_MOBILE
} from 'src/constans/svgIcons'
import { SvgTypes } from 'src/components/SvgTypes/SvgTypes'
import {AttachmentState, PostMessagesTypes} from 'src/constans/constans'

import {
  VoiceButtonContainer,
  VoiceButtonWrapper,
  VoiceMicrophoneButton
} from './VoiceButton.styles'

interface Props {
  isMobile: boolean
  setIsShowVoiceButton: Dispatch<SetStateAction<boolean>>
  widgetSource: MessageEventSource
}

const VoiceButton: FC<Props> = ({
  isMobile,
  setIsShowVoiceButton,
  widgetSource
}) => {
  const svgIconType = useMemo(() => (isMobile ? MICROPHONE_MOBILE : MICROPHONE), [isMobile])
  const [currentAttachment] = useLocalStorage('currentAttachment', AttachmentState.ATTACHMENT_PAPER_CLIP_OFF)
  const hasAttachmentButton = useMemo(() => currentAttachment === AttachmentState.ATTACHMENT_PAPER_CLIP_ON, [currentAttachment])

  const voiceButtonHandler = () => {
    setIsShowVoiceButton(false)

    widgetSource.postMessage({
      type: PostMessagesTypes.WIDGET_CANCEL_VOICE_LISTENING
    }, '*')
  }

  return (
    <VoiceButtonWrapper isMobile={isMobile}>
      <VoiceButtonContainer>
        <VoiceMicrophoneButton
          isMobile={isMobile}
          hasAttachmentButton={hasAttachmentButton}
          onClick={voiceButtonHandler}
        >
          <SvgTypes type={svgIconType}/>
        </VoiceMicrophoneButton>
      </VoiceButtonContainer>
    </VoiceButtonWrapper>
  )
}

export default VoiceButton
