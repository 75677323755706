import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { Button } from 'src/components/common';
import SvgTypes from 'src/components/SvgTypes';
import { BACK_ARROW, BACK_ARROW_MOBILE } from 'src/constans/svgIcons';
import { BackButtonStyled } from 'src/components/RiskAssessmentQuestionnaire/style/BackButton.styled';
import { useBackMutation } from 'src/hooks/api/useRiskAssessmentQuestionaire';
import { useGetChatId } from 'src/hooks/chatId';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';
import { QuestionnairesTypes } from 'src/constans/constans';


export const BackButton = ({
  isMobile,
  showBackButton,
  currentPage,
  isResultScreen,
  botTypePreference
}) => {
  const {currentChatId} = useGetChatId();
  const {mutate: backRequest, isLoading} = useBackMutation(currentPage);
  const [, setCurrentPage] = useLocalStorage('currentQuestionnairePage', '');
  const { stopSpeaking } = useProcessingTextToVoiceByType(botTypePreference)

  const onClickHandler = () => {
    if (isLoading) return
    stopSpeaking()
    if (isResultScreen) {
      setCurrentPage(QuestionnairesTypes.ALL_QUESTIONNAIRES)
    } else {
      backRequest({chat_id: currentChatId})
    }
  }

  return (
    <BackButtonStyled isMobile={isMobile} isLoading={isLoading}>
      {showBackButton &&
      <Button>
        <SvgTypes
          type={isMobile ? BACK_ARROW_MOBILE : BACK_ARROW}
          onClick={onClickHandler}
        />
      </Button>}
    </BackButtonStyled>
  );
};
