import React, { useRef, useMemo, useState, useEffect, Dispatch, FC, SetStateAction } from 'react';
import { Widget } from 'src/pages/Widget/Widget';
import { SwitchTabStyled } from 'src/pages/SwitchTab/SwitchTab.styled';
import { QuestionnairesPage } from 'src/pages/QuestionnairesPage/QuestionnairesPage';
import { getDeviceType, isMacLike } from 'src/utils/inspectDeviceType';
import { useActiveTab } from 'src/hooks/useActiveTab';
import { Tabs } from 'src/constans/constans';
import FamilyTreeModal from 'src/pages/FamilyTreePage/FamilyTreeModal/FamilyTreeModal';
import SwitchTabHeader from 'src/pages/SwitchTab/SwitchTabHeader';
import { FamilyTreeSettingsInterface } from 'src/models/RiskAssessmentQuestionnaire';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';

interface Props {
  setWidgetSource: Dispatch<SetStateAction<MessageEventSource>>
  setIsShowVoiceButton: Dispatch<SetStateAction<boolean>>
  botTypePreference: string
  logOut: () => void
  stopSpeakingUtterance: () => void
}

export const SwitchTab: FC<Props> = ({
  setWidgetSource,
  setIsShowVoiceButton,
  botTypePreference,
  logOut,
  stopSpeakingUtterance
}) => {
  const isMobile = getDeviceType() === 'mobile';
  const { activeTab, setActiveTab } = useActiveTab();
  const isWidgetActiveTab = useMemo(() => activeTab === Tabs.TAB_1, [activeTab])
  const [prevTab, setPrevTab] = useState(activeTab)
  const [familyTreeSettings, setFamilyTreeSettings] = useState<FamilyTreeSettingsInterface>({
    isOpen: false,
    isLoading: false
  })
  const reactFlowRef = useRef(null)
  const { speak } = useProcessingTextToVoiceByType(botTypePreference)

  const sendVoiceSpeakingMessage = (currentTab, isManualClick: boolean) => {
    if (isManualClick) {
      stopSpeakingUtterance()
    }

    if (isMacLike) {
      speak({ text: `You have switched to tab ${currentTab === Tabs.TAB_1 ? 'Chat' : 'Questionnaires'}` })
    } else {
      setTimeout(() => {
        speak({ text: `You have switched to tab ${currentTab === Tabs.TAB_1 ? 'Chat' : 'Questionnaires'}` })
      }, 0)
    }
  }

  const switchTabHandler = (currentTab: Tabs) => {
    setActiveTab(currentTab)
    setPrevTab(currentTab)

    if (activeTab === currentTab) return
    sendVoiceSpeakingMessage(currentTab, true)
  }

  useEffect(() => {
    if (activeTab !== prevTab) {
      sendVoiceSpeakingMessage(activeTab, false)
    }
  }, [activeTab, prevTab]);

  return (
    <SwitchTabStyled
      isMobile={isMobile}
      isWidgetActiveTab={isWidgetActiveTab}
    >
      <SwitchTabHeader
        isWidgetActiveTab={isWidgetActiveTab}
        switchTabHandler={switchTabHandler}
      />
      <div className="outlet">
        <Widget
          isWidgetActiveTab={isWidgetActiveTab}
          setWidgetSource={setWidgetSource}
          setIsShowVoiceButton={setIsShowVoiceButton}
          logOut={logOut}
        />
        <QuestionnairesPage
          isWidgetActiveTab={isWidgetActiveTab}
          botTypePreference={botTypePreference}
          familyTreeSettings={familyTreeSettings}
          setFamilyTreeSettings={setFamilyTreeSettings}
        />
        {familyTreeSettings.isOpen && <FamilyTreeModal
          reactFlowRef={reactFlowRef}
          setFamilyTreeSettings={setFamilyTreeSettings}
        />}
      </div>
    </SwitchTabStyled>
  )
}
