import React from 'react';
import { CommonPageTemplate } from 'src/components/RiskAssessmentQuestionnaire/CommonPageTemplate';
import { getDeviceType } from 'src/utils/inspectDeviceType';

export const RiskAssessmentQuestionnairePage = ({
  children,
  questionnaire_progress,
  custom_tags,
  botTypePreference
}) => {
  const isMobile = getDeviceType() === 'mobile';

  return (
    <>
      <CommonPageTemplate
        questionnaire_progress={questionnaire_progress}
        custom_tags={custom_tags}
        isMobile={isMobile}
        botTypePreference={botTypePreference}
      >
        {children}
      </CommonPageTemplate>
    </>
  );
};
