import { isMacLike } from 'src/utils/inspectDeviceType'

interface SetUpSpeechSynthesisInterface {
  defaultVoice: SpeechSynthesisVoice
  defaultRate: number
  defaultPitch: number
}

const getBrowser = () => {
  const userAgent = navigator.userAgent
  let browserName = ''

  if (userAgent.match(/edg/i)) {
    browserName = 'Edge'
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera'
  }

  return browserName
}

export const setUpSpeechSynthesis = (): SetUpSpeechSynthesisInterface => {
  const speechSynthesis = window.speechSynthesis as SpeechSynthesis
  let voices = speechSynthesis.getVoices()

  speechSynthesis.onvoiceschanged = () => {
    voices = speechSynthesis.getVoices()
  }

  const browserType = getBrowser()
  let defaultVoice: SpeechSynthesisVoice
  let defaultRate: number
  let defaultPitch: number

  if (isMacLike) {
    switch (browserType) {
      case 'Firefox':
      case 'Chrome':
      case 'Edge':
        defaultVoice = voices.find(voice => voice.voiceURI.toLowerCase().includes('samantha'))
        defaultRate = 0.75
        defaultPitch = 1.1
        break
      case 'Safari':
        defaultVoice = voices.find(voice => voice.voiceURI.toLowerCase().includes('samantha'))
        defaultRate = 0.85
        defaultPitch = 1.1
        break
    }
  } else {
    switch (browserType) {
      case 'Firefox':
        defaultVoice = voices.find(voice => voice.voiceURI.toLowerCase().includes('zira'))
        defaultRate = 0.95
        defaultPitch = 1
        break
      case 'Chrome':
        defaultRate = 0.85
        defaultPitch = 1
        break
      case 'Edge':
        defaultVoice = voices.find(voice => voice.voiceURI.toLowerCase().includes('jenny'))
        defaultRate = 0.85
        defaultPitch = 1
        break
    }
  }

  return {
    defaultVoice,
    defaultRate,
    defaultPitch
  }
}
