export const CLOSE = 'close';

export enum InputTypes {
  INPUT_COUNTER = 'INPUT_COUNTER',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export enum CustomTags {
  INIT = 'INIT',
  FINISH = 'FINISH',
  RETURN = 'RETURN',
  ENABLED = 'ENABLED',
  COME_BACK = 'COME_BACK',
  SUBMIT = 'SUBMIT',
  AFFIRMATION = 'AFFIRMATION'
}

export enum QuestionnaireStatuses {
  BLOCKED = 'BLOCKED',
  READY_TO_START = 'READY_TO_START',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum QuestionnairesTypes {
  FAMILY_PEDIGREE = 'family_pedigree',
  RISK_ASSESSMENT = 'risk_assessment',
  ALL_QUESTIONNAIRES = 'all_questionnaires'
}

export enum QuestionTypes {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  USER_INPUT = 'USER_INPUT',
  DROPDOWN_SELECT = 'DROPDOWN_SELECT',
  DEFAULT = 'DEFAULT'
}

export enum Tabs {
  TAB_1 = 'tab1',
  TAB_2 = 'tab2'
}

export enum CustomAttributes {
  GO_TO_QUESTIONNAIRE_RAQ = 'go_to_questionnaire_raq',
  GO_TO_QUESTIONNAIRE_FPQ = 'go_to_questionnaire_fpq',
  START_FPQ_FLOW = 'start_fpq_flow',
  GO_TO_QUESTIONNAIRES = 'go_to_questionnaires',
  START_CONVERT_VOICE_TO_TEXT = 'start_convert_voice_to_text',
  STOP_CONVERT_VOICE_TO_TEXT = 'stop_convert_voice_to_text',
  LOG_OUT = 'log_out',
  ATTACHMENT_STATE_CHANGE = 'attachment_state_change'
}

export enum PostMessagesTypes {
  WIDGET_PERSISTENT_MENU_ACTION = 'widget-persistent-menu-action',
  WIDGET_CANCEL_VOICE_LISTENING = 'widget-cancel-voice-listening',
  WIDGET_CANCEL_VOICE_SPEAKING = 'widget-cancel-voice-speaking',
  WIDGET_SAY_UTTERANCE = 'widget-say-utterance',
  WIDGET_OPEN_IFRAME = 'widget-open-iframe'
}

export enum BotTypePreference {
  VOICE = 'VOICE',
  TEXT = 'TEXT'
}

export enum AttachmentState {
  ATTACHMENT_PAPER_CLIP_OFF = 'attachment_paper_clip_off',
  ATTACHMENT_PAPER_CLIP_ON = 'attachment_paper_clip_on'
}
