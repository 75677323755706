import styled, { css } from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.isMobile ? '10px 16px' : '14px 100px')};
  border-bottom: 1px solid ${props => props.theme.colors.headerBorderColor};
  margin-bottom: 16px;
  background-color: ${props => props.theme.colors.headerBackground};
  
  ${props => !props.isMobile && css`
    @media (max-width: 920px) {
      padding: 28px;
    }
  `}
`

export const ControlButton = styled.button`
  background-color: ${props => props.theme.colors.transparent};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: ${props => (props.isMobile ? 28 : 40)}px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.colors.additionalText};
  cursor: pointer;
  
  &:last-child {
    margin-right: 0;
  }
`

export const ControlPanelContainer = styled.div`
  display: flex;
`

export const LogoContainer = styled.div`
  flex-grow: 1;
  
  & img {
    display: block;
    width: 124px;
    height: 34px;
  }
`

export const ButtonTitle = styled.p`
  margin: 0 8px 0 0;

  ${props => !props.isMobile && css`
    @media (max-width: 720px) {
     display: none;
    }
  `}
`
