import { useLocalStorage } from 'usehooks-ts';
import { Button } from 'src/components/common';
import React from 'react';
import { FinishPageStyled } from 'src/components/RiskAssessmentQuestionnaire/style/FinishPage.styled';
import { QuestionTitle } from 'src/components/RiskAssessmentQuestionnaire/QuestionTitle';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { useActiveTab } from 'src/hooks/useActiveTab';
import { useGetChatId } from 'src/hooks/chatId'
import { QuestionnairesTypes, Tabs } from 'src/constans/constans';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';
import { openLinkInNewTab } from 'src/utils/openLinkInNewTab';

export const FinishPage = ({ data, botTypePreference, familyTreeSettings }) => {
  const isMobile = getDeviceType() === 'mobile';
  const [, setCurrentPage] = useLocalStorage('currentQuestionnairePage', '');
  const { setActiveTab } = useActiveTab();
  const { stopSpeaking } = useProcessingTextToVoiceByType(botTypePreference)
  const { currentChatId } = useGetChatId()

  const onClickHandler = (buttonTitle) => {
    //todo: change to more unique condition
    if (buttonTitle === 'View my Cancer Family History') {
      openLinkInNewTab(currentChatId)
    } else {
      setCurrentPage(QuestionnairesTypes.ALL_QUESTIONNAIRES)
      setActiveTab(Tabs.TAB_1)
    }

    stopSpeaking()
  }

  const isDisabledButton = (buttonTitle: string): boolean => {
    return buttonTitle === 'View my Cancer Family History' ? familyTreeSettings.isLoading : false
  }

  return (
    <FinishPageStyled isMobile={isMobile}>
      {data.messages.map((message, index) => (
        <QuestionTitle
          key={index}
          isLastElement={index === data.messages.length - 1}
        >
          {message}
        </QuestionTitle>
      ))}
      {data.single_select_payloads.map((button, index) => (
        <Button
          key={index}
          className='submitButton'
          onClick={() => onClickHandler(button.title)}
          disable={isDisabledButton(button.title)}
        >
          {button.title}
        </Button>
      ))}
    </FinishPageStyled>
  )
}
