export const isLocalhost = window.location.hostname === 'localhost';

export const SERVER_URL = isLocalhost ? window.location.origin : 'https://catalyst-new-widget.botscrew.net';

export const WIDGET_BASE_URL = isLocalhost ? 'http://localhost:3030' : 'https://catalyst-prod.botscrew.net/widget';

export const BOT_ID = 1

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const GET_USER_ID = `${BACKEND_URL}/user`;

export const USER_NOTIFICATIONS_SETTINGS = `${BACKEND_URL}/user/notifications`;

export const LOGIN = `${BACKEND_URL}/login`;

export const LOGOUT = `${BACKEND_URL}/logout`;

export const INIT_USER = `${BACKEND_URL}/init`;

export const CREATE_PASSWORD = `${BACKEND_URL}/password`;

export const FIRST_RESET_EMAIL = `${BACKEND_URL}/reset-password/request-reset`;

export const RESET_PASSWORD_TOKEN_VALIDATION = `${BACKEND_URL}/reset-password/token-validation`;

export const RESET_PASSWORD_CHANGE_PASSWORD = `${BACKEND_URL}/reset-password/change-password`;

export const USER_INFO_CREDENTIALS = `${BACKEND_URL}/user-info/credentials`;

export const USER_INFO_PREFERENCE = `${BACKEND_URL}/user-info/preference`;

export const USER_INFO_STATE_PREFERENCE = `${BACKEND_URL}/user-info/state`;

export const SAVE_REPLY_RA = `${BACKEND_URL}/risk-assessment/reply`;
export const SAVE_REPLY_FP = `${BACKEND_URL}/family-pedigree/reply`;

export const GET_HISTORY_RA = `${BACKEND_URL}/risk-assessment/history`;
export const GET_HISTORY_FP = `${BACKEND_URL}/family-pedigree/history`;

export const GET_NEXT_QUESTION_RA = `${BACKEND_URL}/risk-assessment/question`;
export const GET_NEXT_QUESTION_FP = `${BACKEND_URL}/family-pedigree/question`;

export const BACK_RA = `${BACKEND_URL}/risk-assessment/back`;
export const BACK_FP = `${BACKEND_URL}/family-pedigree/back`;

export const NOTIFICATION_RA = `${BACKEND_URL}/risk-assessment/notification`;
export const NOTIFICATION_FP = `${BACKEND_URL}/family-pedigree/notification`;

export const SUMMARY_RA = `${BACKEND_URL}/risk-assessment/summary`;

export const GET_INFO_RA = `${BACKEND_URL}/risk-assessment/info`
export const GET_INFO_FP = `${BACKEND_URL}/family-pedigree/info`

export const PEDIGREE_RETURN = `${BACKEND_URL}/family-pedigree/return`

export const GET_FAMILY_TREE_INFO = `${BACKEND_URL}/family-tree`

export const GET_PERSISTENT_MENU = `${BACKEND_URL}/widget/${BOT_ID}/catalyst/persistentMenu`

export const GET_VOICE = `${BACKEND_URL}/settings/voice`

export const FAMILY_TREE_FILE = `${BACKEND_URL}/save-family-tree-file`

export const GET_FAMILY_TREE_FILE = 'https://catalyst-prod.botscrew.net/api/family-tree-file'
