import { getData } from 'src/api/apiGet';
import { GET_VOICE } from 'src/config';
import { BotTypePreference } from 'src/constans/constans';
import {postRequest} from 'src/api/apiPost';

type VoiceChatInfo = {
  bot_type_preference: BotTypePreference
}

export const getVoiceChatInfo = async (chatId: string): Promise<VoiceChatInfo> => {
  try {
    const result = await getData(GET_VOICE, { chatId })
    if (result.ok) {
      return result.json()
    } else {
      return null
    }
  } catch (e) {
    console.log(e)
  }
}

export const setVoiceChatInfo = async (chatId: string, voiceStatus: string) => {
  try {
    const result = await postRequest(GET_VOICE, { bot_type_preference: voiceStatus }, { chatId })
    if (result.ok) {
      return result.json()
    }
  } catch (e) {
    console.log(e)
  }
}
