import styled from 'styled-components';

const LoginPage = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100svh;
  background-size: cover;
  background-color: ${props => props.theme.colors.additionalWidgetMessageColor};
  color: ${props => props.theme.colors.additionalText};
`;

const LoginPageContent = styled.div`
  max-width: 365px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1 0 auto;
  
  & > p {
    margin: 0 0 44px;
  }
  
  label {
    display: inherit;
    justify-content: start;
  }
  
  input {
    width: ${props => (props.isMobile ? '100%' : '365px')};
    margin-bottom: 16px;
    padding: 12px;
  }
  
  input::placeholder {
    padding: 12px;
  }
  
  & form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 24px;
  }
  
  .forgotPassLink {
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: ${props => props.theme.colors.additionalText};
    text-decoration-line: underline;
  }
`

const LoginSubmitPage = styled.div`
  position: relative;
  
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colors.primary};
    width: 100%;
    max-width: 365px;
    height: 48px;
    border-radius: 16px;
    color: ${props => props.theme.colors.white};
    border: none;
    font-family: ${props => props.theme.fonts};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.mainButtonHoverColor};
    }

    &:active {
      background-color: ${props => props.theme.colors.mainButtonFocusColor};
    }

    &:disabled {
      background-color: ${props => props.theme.colors.mainButtonDisabledColor};
      cursor: auto;
    }
  }
`;

const ForgotPasswordLink = styled.p`
  margin: 0 0 80px;
  flex-grow: ${props => (props.isMobile ? 1 : 0)};
`

const LoginPageInputWithSvg = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  svg {
    position: absolute; //you can make icon on the input like this.
    top: 50%; //icon will be center of the input from top to bottom.
    right: 12px; //right position.
    transform: translateY(-50%);
    cursor: pointer;
  }
`

const LoginPageErrorMessage = styled.p`
  color: ${props => props.theme.colors.errorMessage};
  font-weight: 500;
  margin: 0;
  position: absolute;
  bottom: 56px;
  left: 0;
`

export { LoginPage, LoginSubmitPage, LoginPageInputWithSvg, LoginPageContent, LoginPageErrorMessage, ForgotPasswordLink };
