import { QuestionnaireStatuses } from 'src/constans/constans';

type UserInput = {
  id: number;
  type: 'SELECTOR' | 'NUMBER' | 'STRING' | 'DYNAMIC' | 'INPUT_COUNTER';
  label_text: string;
  max_value: number;
  is_large: boolean;
  placeholder: string;
};

type SelectOptions = {
  id : number;
  title: string;
  chosen: boolean;
};

type QuestionnaireProgress = {
  answered_blocks: number;
  total_blocks: number;
};

type QuestionType = 'SINGLE_SELECT' | 'MULTI_SELECT' | 'USER_INPUT';

export enum CustomTags {
  INIT = 'INIT',
  FINISH = 'FINISH',
  RESULT = 'RESULT'
}

type UserInputAnswer = {
  attribute_name: string;
  value: string;
};

type PayloadsAnswerAttributes = {
  name: string;
  value: string;
};

type SingleSelectPayloadAnswer = {
  title: string;
  attributes: PayloadsAnswerAttributes[];
  redirect_atom_id: number;
};
type MultiSelectPayloadAnswer = {
  title: string;
  attributes: PayloadsAnswerAttributes[];
};

type UserCounter = {
  attributeName: string;
  label_text: string;
  max_value: number;
}

export type SaveReplyAnswer = {
  atom_chain: AtomChain;
  chat_id: number;
  reply_type: string;
  single_select_payload?: SingleSelectPayloadAnswer;
  multi_select_payloads?: MultiSelectPayloadAnswer;
  user_inputs?: UserInputAnswer[];
};

export type BackAnswer = {
  chat_id: number;
};

export type AtomChain = {
    start_id: number;
    end_id: number;
};

export type MultiSelectOption = {
  title: string;
  id: number
  chosen: boolean
}

export type GetNextQuestion = {
  atom_chain: AtomChain;
  question_title: string;
  chat_id: number;
  messages: string[];
  multi_select_payloads?: MultiSelectOption[];
  single_select_payloads?: SelectOptions[];
  user_input_payloads?: UserInput[];
  question_type: QuestionType;
  questionnaire_progress: QuestionnaireProgress;
  custom_tags: CustomTags[];
  user_counters: UserCounter[];
};

export interface GeneralQuestionnaire {
  chat_id: number
  questionnaire_progress: QuestionnaireProgress
  status: QuestionnaireStatuses
}

export interface FamilyTreeSettingsInterface {
  isOpen: boolean
  isLoading: boolean
}
