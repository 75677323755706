import React, { useState, useMemo, useEffect } from 'react';
import Select, { components, StylesConfig } from 'react-select';
import { Button } from 'src/components/common';
import { QuestionTitle } from 'src/components/RiskAssessmentQuestionnaire/QuestionTitle';
import {
  DropdownSelectButtonsFooterStyled,
  DropdownSelectPageStyled
} from 'src/pages/RiskAssessmentQuestionnairePage/DropdownSelectPage.styled';
import { CHEVRON_DOWN, DROPDOWN_CLOSE } from 'src/constans/svgIcons';
import SvgTypes from 'src/components/SvgTypes';
import {QuestionTypes} from 'src/constans/constans';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';

const fontStyles = (fz = 16, fw = 400, lh = '24px') => ({
  fontFamily: 'Lato, sans-serif',
  fontStyle: 'normal',
  fontWeight: fw,
  fontSize: fz,
  lineHeight: lh
})

const colourStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    border: '1px solid #C0E2FB',
    boxShadow: '0 0 3px #C0E2FB',
    borderRadius: 8,
    '&:hover': {}
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#A3A3A3',
    margin: 0,
    ...fontStyles()
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '11px 0 11px 12px',
    flexWrap: 'nowrap'
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#E9F7FF',
    margin: 0,
    borderRadius: 4
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#002D5E',
    padding: '4px 0 4px 4px',
    ...fontStyles(14, 400, '16px')
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#E9F7FF'
    }
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    marginTop: 13,
    marginBottom: 13,
    backgroundColor: '#5197BD'
  }),
  menu: (styles) => ({
    ...styles,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
    borderRadius: '0 0 8px 8px'
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    borderRadius: '0 0 8px 8px'
  }),
  option: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? '#E9F7FF' : undefined,
    color: '#616581',
    cursor: isDisabled ? 'not-allowed' : 'default',
    padding: 16,
    ...fontStyles(14, undefined, '14px'),
    '&:hover': {
      backgroundColor: '#E9F7FF'
    },
    '&:active': {
      ...styles[':active'],
      backgroundColor: '#E9F7FF'
    }
  })
}

export const DropdownSelectPage = ({
  data,
  chatId,
  onNext,
  botTypePreference
}) => {
  const chosenValue = useMemo(() => {
    const selectedItem = data.single_select_payloads.find(item => item.chosen)
    if (!selectedItem) return

    return {
      label: selectedItem.title,
      value: selectedItem.title,
      id: selectedItem.id
    }
  }, [data.single_select_payloads])
  const [selectedValue, setSelectedValue] = useState(chosenValue)
  const { speak, stopSpeaking } = useProcessingTextToVoiceByType(botTypePreference)

  useEffect(() => {
    const timeOutId = setTimeout(() => speak({ text: 'Continue' }), 0)
    return () => clearTimeout(timeOutId)
  }, [data]);

  const selectData = useMemo(() => {
    return data.single_select_payloads.map(option => ({
      label: option.title,
      value: option.title,
      id: option.id
    }))
  }, [data.single_select_payloads])

  const handleOnChange = (value) => {
    const singleValue = value.slice(-1).pop()
    setSelectedValue(singleValue)
  }

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <SvgTypes type={DROPDOWN_CLOSE}/>
      </components.MultiValueRemove>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SvgTypes type={CHEVRON_DOWN}/>
      </components.DropdownIndicator>
    )
  }

  const dropdownClickHandler = () => {
    const nextData = {
      question_id: data.question_id,
      chat_id: chatId,
      reply_type: QuestionTypes.SINGLE_SELECT,
      single_select_payload: {
        title: selectedValue.value,
        id: selectedValue.id
      }
    }

    stopSpeaking()
    return onNext(nextData)
  }

  return (
    <>
      <DropdownSelectPageStyled>
        {data.messages.map((message, index) => (
          <QuestionTitle
            key={index}
            isLastElement={index === data.messages.length - 1}
          >
            {message}
          </QuestionTitle>
        ))}
        <Select
          placeholder='Select cancer diagnosis'
          options={selectData}
          components={{ MultiValueRemove, DropdownIndicator }}
          onChange={handleOnChange}
          isClearable={false}
          isSearchable={false}
          isMulti={true}
          styles={colourStyles}
          value={selectedValue}
          menuPlacement='auto'
          minMenuHeight={250}
        />
      </DropdownSelectPageStyled>
      <DropdownSelectButtonsFooterStyled>
        <Button
          type={'submit'}
          className='submitButton'
          disable={!selectedValue}
          onClick={dropdownClickHandler}
        >
          Continue
        </Button>
      </DropdownSelectButtonsFooterStyled>
    </>
  )
};
