import React, { useEffect, useState } from 'react';
import { Button } from 'src/components/common';
import {
  createArrayForRequest,
  createMultiSelectObject,
} from 'src/pages/RiskAssessmentQuestionnairePage/helpers/createMultiSelectObject';
import { useGetChatId } from 'src/hooks/chatId';
import { QuestionTitle } from 'src/components/RiskAssessmentQuestionnaire/QuestionTitle';
import { MultiSelectChoiceButton } from 'src/components/RiskAssessmentQuestionnaire/MultiSelectChoiceButton';
import {
  MultiSelectPageStyled,
  MultiSelectPageCheckboxesStyled,
  MultiSelectPageFooterStyled,
} from 'src/pages/RiskAssessmentQuestionnairePage/MultiSelectPage.styled';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';

export const MultiSelectPage = ({
  data,
  onNext,
  botTypePreference
}) => {
  const isMobile = getDeviceType() === 'mobile';
  const {currentChatId} = useGetChatId();
  const [checkboxesState, setCheckboxesState] = useState(createMultiSelectObject(data.multi_select_payloads));
  const { speak, stopSpeaking } = useProcessingTextToVoiceByType(botTypePreference)
  const onChange = (key: string) => {
    const updatedState = checkboxesState.map((checkbox) => {
      return checkbox.title === key ? {...checkbox, checked: !checkbox.checked} : checkbox
    });
    setCheckboxesState(updatedState);
  }
  const isDisable = !checkboxesState.some(checkbox => checkbox.checked)

  const multiSelectClickHandler = () => {
    const nextData = {
      question_id: data.question_id,
      chat_id: currentChatId,
      reply_type: data.question_type,
      multi_select_payloads: createArrayForRequest(checkboxesState)
    }

    stopSpeaking()
    onNext(nextData)
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => speak({ text: 'Button Continue' }), 0)
    return () => clearTimeout(timeOutId)
  }, [])

  return (
    <MultiSelectPageStyled isMobile={isMobile}>
      {data.messages.map((message, index) => (
        <QuestionTitle
          key={index}
          index={index}
          isLastElement={index === data.messages.length - 1}
        >
          {message}
        </QuestionTitle>
      ))}
      <MultiSelectPageCheckboxesStyled isMobile={isMobile}>
        {checkboxesState.map(({title, checked}, index) => {
          return <MultiSelectChoiceButton key={index} label={title} checked={checked} onChange={() => onChange(title)}/>
        })}
      </MultiSelectPageCheckboxesStyled>
      <MultiSelectPageFooterStyled isMobile={isMobile}>
        <Button
          className='submitButton'
          disable={isDisable}
          onClick={multiSelectClickHandler}
        >
          Continue
        </Button>
      </MultiSelectPageFooterStyled>
    </MultiSelectPageStyled>
  )
}
