import React, { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { CallToActionsInterface, getPersistentMenu } from 'src/api/persistentMenuRequest';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useActiveTab } from 'src/hooks/useActiveTab';
import { processingByAttribute } from 'src/utils/processingByAttribute';
import { PostMessagesTypes } from 'src/constans/constans';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';
import { SvgTypes } from 'src/components/SvgTypes/SvgTypes';
import { CHECK_ICON_SVG } from 'src/constans/svgIcons';
import { theme } from 'src/themes/Theme';

import {
  PersistentMenuButton,
  PersistentMenuContainer,
  PersistentMenuItem,
  PersistentMenuItemButton,
  PersistentMenuList,
  PersistentMenuPopUp
} from './PersistentMenu.styles';

interface Props {
  isMobile: boolean
  isMenuOpen: boolean
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
  widgetSource: MessageEventSource
  botTypePreference: string
  logOut: () => void
  stopSpeakingUtterance: () => void
}

const PersistentMenu: FC<Props> = ({
  isMobile,
  isMenuOpen,
  setIsMenuOpen,
  widgetSource,
  botTypePreference,
  logOut,
  stopSpeakingUtterance
}) => {
  const chatId = localStorage.getItem('chatId')
  const [persistentMenu, setPersistentMenu] = useState<CallToActionsInterface[]>([])
  const { setActiveTab } = useActiveTab()
  const persistentMenuRef = useClickOutside(() => setIsMenuOpen(false))
  const [isFirstRender, setIsFirstRender] = useState(true)
  const isNeedPersistentMenuUpdate = isFirstRender ? true : isMenuOpen
  const { speak } = useProcessingTextToVoiceByType(botTypePreference)

  useEffect(() => {
    if (isNeedPersistentMenuUpdate) {
      setIsFirstRender(false)
      getPersistentMenu(chatId).then(res => {
        setPersistentMenu(res.callToActions)
      }).catch(() => logOut())
    }
  }, [chatId, isMenuOpen, isNeedPersistentMenuUpdate])

  useEffect(() => {
    const menuTextObject = {
      text: 'You opened the menu',
      type: 'persistent-menu-button'
    }
    if (isMenuOpen) {
      stopSpeakingUtterance()
      speak(menuTextObject)
    }
  }, [isMenuOpen])

  const onClickHandler = (item) => {
    if (!item.enabled) return


    switch (item.type) {
      case 'web_url':
        if (item.enableMessengerExtensions) {
          widgetSource.postMessage({
            type: PostMessagesTypes.WIDGET_OPEN_IFRAME,
            url: item.url
          }, '*')
        } else {
          const target = item?.url?.endsWith('openInWidgetPage=true') ? '_parent' : '_blank'
          window.open(item.url, target)
        }
        break
      case 'postback':
        widgetSource.postMessage({
          type: PostMessagesTypes.WIDGET_PERSISTENT_MENU_ACTION,
          payload: item.payload,
          title: item.title
        }, '*')
        break
      case 'custom_redirect':
        processingByAttribute(item.payload, setActiveTab)
        break
    }

    setIsMenuOpen(false)
  }

  return (
    <PersistentMenuContainer
      isMobile={isMobile}
      ref={persistentMenuRef}
    >
      <PersistentMenuButton
        isMobile={isMobile}
        onClick={() => setIsMenuOpen(prevState => !prevState)}
      >
        Menu
      </PersistentMenuButton>
      {isMenuOpen && !!persistentMenu.length && (
        <PersistentMenuPopUp
          isMobile={isMobile}
        >
          <PersistentMenuList>
            {persistentMenu.map((item, index) => (
              <PersistentMenuItem key={index} enbled={item.enabled}>
                <PersistentMenuItemButton
                  enbled={item.enabled}
                  onClick={() => onClickHandler(item)}
                >
                  {item.title}
                  {item.completed && <SvgTypes
                    type={CHECK_ICON_SVG}
                    color={theme.colors.white}
                  />}
                </PersistentMenuItemButton>
              </PersistentMenuItem>
            ))}
          </PersistentMenuList>
        </PersistentMenuPopUp>
      )}
    </PersistentMenuContainer>
  )
}

export default PersistentMenu;
