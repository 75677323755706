import { BOT_ID, WIDGET_BASE_URL } from 'src/config'

const attributes = {
  type: 'text/javascript',
  id: 'chatbot-initials-script',
  src: `${WIDGET_BASE_URL}/script-chatbot.js`,
  'server-url': 'https://catalyst-prod.botscrew.net/api',
  'bot-id': BOT_ID,
  'data-open-widget': 'true',
  'data-container-id': 'widget-container'
}

export const widgetInitializationConfig = () => {
  const scriptTag = document.createElement('script')
  setAttributes(scriptTag, attributes)
  document.body.appendChild(scriptTag)
}

const setAttributes = (el, attrs) => {
  for (const key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}
