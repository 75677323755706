import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { unescape } from 'lodash';
import { IntroPageContentStyled, IntroPageStyled } from 'src/components/RiskAssessmentQuestionnaire/style/IntroPage.styled';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { Button } from 'src/components/common';
import { CustomTags } from 'src/constans/constans';
import {useProcessingTextToVoiceByType} from 'src/utils/useProcessingTextToVoiceByType';

export const IntroPage = ({
  data,
  onNext,
  chatId,
  botTypePreference
}) => {
  const isMobile = getDeviceType() === 'mobile';
  const { stopSpeaking } = useProcessingTextToVoiceByType(botTypePreference)

  const isBackButton = (index) => {
    const lastButtonIndex = data.single_select_payloads.length - 1
    const isComeBackButton = data.custom_tags.some(tag => tag === CustomTags.COME_BACK)
    return isComeBackButton && index === lastButtonIndex
  }

  const onClickHandler = (index, button) => {
    onNext({
      question_id: data.question_id,
      chat_id: chatId,
      reply_type: data.question_type,
      single_select_payload: { title: button.title, id: button.id }
    })

    stopSpeaking()
  }

  return (
    <IntroPageStyled id='introPage' isMobile={isMobile}>
      <IntroPageContentStyled isMobile={isMobile}>
        {data.messages.map((message) => (
          <span
            key={uuidv4()}
            dangerouslySetInnerHTML={{__html: unescape(message)}}
          />
        ))}
      </IntroPageContentStyled>
      {data.single_select_payloads.map((button, index) => (
        <Button
          className={isBackButton(index) ? 'ghostButton' : 'submitButton'}
          disable={false}
          key={uuidv4()}
          onClick={() => onClickHandler(index, button)}
        >
          {button.title}
        </Button>
      ))}
    </IntroPageStyled>
  );
};
