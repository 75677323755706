import styled from 'styled-components'

export const VoiceButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 82, 168, .3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.isMobile ? '0' : '106px 0 14px')};
`

export const VoiceButtonContainer = styled.div`
  position: relative;
  max-width: 946px;
  min-height: 206px;
  width: 100%;
  height: 100%;
  align-self: center;
`

export const VoiceMicrophoneButton = styled.button`
  border: none;
  background-color: ${props => props.theme.colors.primary};
  box-shadow: 0 4px 21px 0 ${props => props.theme.colors.primary};
  border-radius: 50%;
  padding: 8px;
  position: absolute;
  bottom: ${props => (props.isMobile ? 20 : 35)}px;
  right: ${props => (props.isMobile ? (props.hasAttachmentButton ? 84 : 52) : props.hasAttachmentButton ? 128 : 80)}px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(1, 82, 168, .3);
    border-radius: 50%;
  }

  &:before {
    animation: ripple 2s linear infinite;
  }

  &:after {
    animation: ripple 2s linear 1s infinite;
  }

  @keyframes ripple{
    0% { transform: scale(1); }
    50% { transform: scale(1.4); opacity:1; }
    100% { transform: scale(1.8); opacity:0; }
  }
`
