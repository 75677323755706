import React, { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';
import { BackButton } from 'src/components/RiskAssessmentQuestionnaire/BackButton';
import { ProgressBar } from 'src/components/common/ProgressBar';
import { CloseButton } from 'src/components/RiskAssessmentQuestionnaire/CloseButton';
import { CustomTags } from 'src/models/RiskAssessmentQuestionnaire';
import { QuestionnairesTypes } from 'src/constans/constans';
import {
  CommonPageHeaderMobileStyled,
  CommonPageHeaderProgressBarStyled,
  CommonPageHeaderStyled,
  CommonPageStyled,
} from 'src/components/RiskAssessmentQuestionnaire/style/CommonPageStyled.styled';

export const CommonPageTemplate = ({
  children,
  isMobile = false,
  questionnaire_progress,
  custom_tags,
  botTypePreference
}) => {
  const [currentPage, setCurrentPage] = useLocalStorage('currentQuestionnairePage', '');
  const showBackButton = !custom_tags.some(tag => [CustomTags.INIT, CustomTags.FINISH].includes(tag))
  const isResultScreen = custom_tags.some(tag => [CustomTags.RESULT].includes(tag))
  const { speak, stopSpeaking } = useProcessingTextToVoiceByType(botTypePreference)

  const currentQuestionnaireTitle = useMemo(() => {
    let name
    switch (currentPage) {
      case QuestionnairesTypes.RISK_ASSESSMENT:
        name = isResultScreen ? 'Risk Assessment/View my results' : 'Risk Assessment'
        break
      case QuestionnairesTypes.FAMILY_PEDIGREE:
        name = 'Biological Family\'s Cancer History'
        break
      default:
        name = ''
    }

    return name
  }, [currentPage])

  const onCloseHandler = () => {
    stopSpeaking()
    speak({ text: 'You clicked on button X' })
    setCurrentPage(QuestionnairesTypes.ALL_QUESTIONNAIRES)
  }

  return (
    <CommonPageStyled isMobile={isMobile}>
      {!isMobile ? (
        <CommonPageHeaderStyled>
          <BackButton
            isMobile={isMobile}
            showBackButton={showBackButton}
            currentPage={currentPage}
            isResultScreen={isResultScreen}
            botTypePreference={botTypePreference}
          />
          <CommonPageHeaderProgressBarStyled>
            <p>{currentQuestionnaireTitle}</p>
            {questionnaire_progress &&
              <ProgressBar
                isMobile={isMobile}
                answeredBlocks={questionnaire_progress.answered_blocks}
                totalBlocks={questionnaire_progress.total_blocks}
              />
            }
          </CommonPageHeaderProgressBarStyled>
          <CloseButton
            isMobile={isMobile}
            onClick={onCloseHandler}
          />
        </CommonPageHeaderStyled>
      ) : (
        <>
          <CommonPageHeaderMobileStyled>
            <BackButton
              isMobile={isMobile}
              showBackButton={showBackButton}
              currentPage={currentPage}
              isResultScreen={isResultScreen}
              botTypePreference={botTypePreference}
            />
            <p>{currentQuestionnaireTitle}</p>
            <CloseButton
              isMobile={isMobile}
              onClick={onCloseHandler}
            />
          </CommonPageHeaderMobileStyled>
          {questionnaire_progress &&
            <ProgressBar
              isMobile={isMobile}
              answeredBlocks={questionnaire_progress.answered_blocks}
              totalBlocks={questionnaire_progress.total_blocks}
            />
          }
        </>
      )}
      {children}
    </CommonPageStyled>
  );
};
