import React from 'react';

interface MyProps {
  className?: string;
  onClick?: (p?: never) => void;
  children?: React.ReactNode | string;
  type?: 'button' | 'submit' | 'reset';
  disable?: boolean;
}

export const Button = ({ className, onClick, type, children, disable, ...props }: MyProps): JSX.Element => {
  const onClickHandler = (e) => {
    e.target.blur()
    onClick && onClick()
  }

  return (
    <button disabled={disable} type={type} onClick={onClickHandler} className={className} {...props}>
      {children}
    </button>
  );
};
