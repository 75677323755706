export const getDeviceType = (): string => {
  const ua: string = navigator.userAgent;
  if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  } else if (/Chrome/i.test(ua)) {
    return 'chrome';
  }
  return 'desktop';
};

export const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent)
