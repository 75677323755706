import { useMemo } from 'react';
import { BotTypePreference, QuestionTypes } from 'src/constans/constans';
import { setUpSpeechSynthesis } from 'src/utils/setUpSpeechSynthesis';

export const useProcessingTextToVoiceByType = (botTypePreference: string) => {
  const speechSynthesis = window.speechSynthesis
  const isVoiceChatOn = useMemo(() => botTypePreference === BotTypePreference.VOICE, [botTypePreference])
  const regExMasc = /<\/?[^>]+(>|$)|&lt;.+?&gt;/g

  const stopSpeaking = () => {
    speechSynthesis.cancel()
  }

  const speak = (message) => {
    const { defaultVoice, defaultRate, defaultPitch } = setUpSpeechSynthesis()
    const hasSingleSelectPayload = [
      QuestionTypes.SINGLE_SELECT,
      QuestionTypes.DROPDOWN_SELECT,
      QuestionTypes.USER_INPUT
    ].includes(message.question_type)

    if (isVoiceChatOn) {
      if (message.messages?.length) {
        message.messages.forEach((text) => {
          const Utterance = new SpeechSynthesisUtterance()
          Utterance.voice = defaultVoice
          Utterance.rate = defaultRate
          Utterance.pitch = defaultPitch
          Utterance.text = text.replace(regExMasc, '')
          setTimeout(() => speechSynthesis.speak(Utterance), 0)
        })
      }

      if (message.text) {
        const Utterance = new SpeechSynthesisUtterance()
        Utterance.voice = defaultVoice
        Utterance.rate = defaultRate
        Utterance.pitch = defaultPitch
        Utterance.text = message.text
        Utterance.volume = (message.volume || message.volume === 0) ? message.volume : 1
        setTimeout(() => speechSynthesis.speak(Utterance), 0)
      }

      if (hasSingleSelectPayload) {
        message.single_select_payloads.forEach(button => {
          const Utterance = new SpeechSynthesisUtterance()
          Utterance.voice = defaultVoice
          Utterance.rate = defaultRate
          Utterance.pitch = defaultPitch
          Utterance.text = button.title
          setTimeout(() => speechSynthesis.speak(Utterance), 0)
        })
      }

      if (message.question_type === QuestionTypes.MULTI_SELECT) {
        message.multi_select_payloads.forEach(item => {
          const Utterance = new SpeechSynthesisUtterance()
          Utterance.voice = defaultVoice
          Utterance.rate = defaultRate
          Utterance.pitch = defaultPitch
          Utterance.text = item.title
          setTimeout(() => speechSynthesis.speak(Utterance), 0)
        })
      }
    }
  }

  return { speak, stopSpeaking }
}
