import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { CatalystLogo } from 'src/components/common'
import { SvgTypes } from 'src/components/SvgTypes/SvgTypes'
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { BotTypePreference, PostMessagesTypes } from 'src/constans/constans';
import { setVoiceChatInfo } from 'src/api/voiceChatApi';
import { useGetChatId } from 'src/hooks/chatId';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';
import PersistentMenu from 'src/components/PersistentMenu/PersistentMenu';
import {
  CHANGE_NOTIFICATIONS,
  SIGN_OUT,
  SPEAKER,
  SPEAKER_OFF
} from 'src/constans/svgIcons'
import { theme } from 'src/themes/Theme';

import {
  ButtonTitle,
  ControlButton,
  ControlPanelContainer,
  HeaderContainer,
  LogoContainer
} from './Header.styled'

interface Props {
  botTypePreference: string
  setBotTypePreference: Dispatch<SetStateAction<BotTypePreference>>
  widgetSource: MessageEventSource
  setIsShowLogOutModal: (p: boolean) => void
  setIsShowNotificationModal: (p: boolean) => void
  isMenuOpen: boolean,
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
  logOut: () => void
  stopSpeakingUtterance: () => void
}

const Header: FC<Props> = ({
  botTypePreference,
  setBotTypePreference,
  widgetSource,
  setIsShowLogOutModal,
  setIsShowNotificationModal,
  isMenuOpen,
  setIsMenuOpen,
  logOut,
  stopSpeakingUtterance
}) => {
  const isMobile = getDeviceType() === 'mobile';
  const { currentChatId } = useGetChatId();
  const { stopSpeaking } = useProcessingTextToVoiceByType(botTypePreference)

  const voiceChatHandler = () => {
    const voiceStatus = botTypePreference === BotTypePreference.VOICE ? BotTypePreference.TEXT : BotTypePreference.VOICE
    setVoiceChatInfo(currentChatId, voiceStatus).then(res => {
      setBotTypePreference(res.bot_type_preference)

      widgetSource.postMessage({
        type: PostMessagesTypes.WIDGET_CANCEL_VOICE_SPEAKING
      }, '*')

      stopSpeaking()
    })
  }

  const logOutHandler = () => setIsShowLogOutModal(true)
  const notificationHandler = () => setIsShowNotificationModal(true)

  const voiceChat = useMemo(() => {
    let title: string
    let icon: string
    switch (botTypePreference) {
      case BotTypePreference.VOICE:
        title = 'Voice chat is on'
        icon = SPEAKER
        break
      case BotTypePreference.TEXT:
        title = 'Voice chat is off'
        icon = SPEAKER_OFF
        break
      default:
        title = 'Voice chat is on'
        icon = SPEAKER
    }

    return { title, icon }
  }, [botTypePreference])

  return (
    <HeaderContainer isMobile={isMobile}>
      <PersistentMenu
        isMobile={isMobile}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        widgetSource={widgetSource}
        botTypePreference={botTypePreference}
        logOut={logOut}
        stopSpeakingUtterance={stopSpeakingUtterance}
      />
      <LogoContainer>
        <CatalystLogo />
      </LogoContainer>
      <ControlPanelContainer>
        <ControlButton
          isMobile={isMobile}
          onClick={voiceChatHandler}
        >
          {!isMobile && <ButtonTitle>{ voiceChat.title }</ButtonTitle>}
          <SvgTypes
            type={voiceChat.icon}
            color={theme.colors.additionalText}
          />
        </ControlButton>
        <ControlButton
          isMobile={isMobile}
          onClick={notificationHandler}
        >
          {!isMobile && <ButtonTitle>Notification preferences</ButtonTitle>}
          <SvgTypes
            type={CHANGE_NOTIFICATIONS}
            color={theme.colors.additionalText}
          />
        </ControlButton>
        <ControlButton
          isMobile={isMobile}
          onClick={logOutHandler}
        >
          {!isMobile && <ButtonTitle>Log out</ButtonTitle>}
          <SvgTypes
            type={SIGN_OUT}
            color={theme.colors.additionalText}
          />
        </ControlButton>
      </ControlPanelContainer>
    </HeaderContainer>
  )
}

export default Header
