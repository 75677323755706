import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/constans/routes';
import { Button } from 'src/components/common';
import { useGetChatId } from 'src/hooks/chatId';
import { useVoiceChatNotificationMutation } from 'src/hooks/api/useProvideData';
import { checkErrorFromRequest } from 'src/utils/checkErrorFromRequest';
import {
  ButtonsContainer,
  VoiceNotificationsPageContent,
  VoiceNotificationsPageStyled
} from 'src/pages/VoiceNotificationsPage/VoiceNotificationsPage.styled';
import { Logo } from 'src/components/common/Logo/Logo';
import { useGetAuthorised } from 'src/hooks/authorised';
import { getDeviceType } from 'src/utils/inspectDeviceType';

export const VoiceNotificationsPage = () => {
  const navigate = useNavigate();
  const isMobile = getDeviceType() === 'mobile';
  const { currentChatId } = useGetChatId();
  const [infoMessage, setInfoMessage] = useState('');
  const { mutateAsync: voiceChatNotificationRequest } = useVoiceChatNotificationMutation();
  const { setIsAuthorised } = useGetAuthorised();

  const startSpeakingByClick = () => {
    const speechSynthesis = window.speechSynthesis
    const Utterance = new SpeechSynthesisUtterance()
    Utterance.text = 'i'
    Utterance.volume = 0
    setTimeout(() => speechSynthesis.speak(Utterance), 0)
  }

  const clickHandler = useCallback(
    type => {
      startSpeakingByClick()
      voiceChatNotificationRequest({ chat_id: currentChatId, bot_type_preference: type }).then(res => {
        if (res.status === 200) {
          setInfoMessage('success');
          setIsAuthorised(true);
          navigate(routes.HOME);
        } else {
          setInfoMessage(res.data.message);
          navigate(checkErrorFromRequest(res.status));
        }
      });
    },
    [currentChatId, navigate, voiceChatNotificationRequest],
  );

  return (
    <VoiceNotificationsPageStyled>
      <Logo isMobile={isMobile} />
      <VoiceNotificationsPageContent isMobile={isMobile}>
        <p>Do you want to use Voice Chat?</p>
        <p>
          Enabling Voice Chat will read aloud messages from the Chatbot and allow
          you to answer them using the microphone on your device.
          You will be able to turn this function on/off anytime during the chat
        </p>
        {!!infoMessage && <p>{infoMessage}</p>}
        <ButtonsContainer isMobile={isMobile}>
          <Button
            className='agreeButton'
            onClick={() => {
              clickHandler('VOICE');
            }}
          >
            Yes, use Voice Chat
          </Button>
          <Button
            className='disagreeButton'
            onClick={() => {
              clickHandler('TEXT');
            }}
          >
            No, do not use Voice Chat
          </Button>
        </ButtonsContainer>
      </VoiceNotificationsPageContent>
    </VoiceNotificationsPageStyled>
  );
};
