import React, { useEffect } from 'react';
import { SingleSelectPage } from 'src/pages/RiskAssessmentQuestionnairePage/SingleSelectPage';
import { MultiSelectPage } from 'src/pages/RiskAssessmentQuestionnairePage/MultiSelectPage';
import { UserInputPage } from 'src/pages/RiskAssessmentQuestionnairePage/UserInputPage';
import { DropdownSelectPage } from 'src/pages/RiskAssessmentQuestionnairePage/DropdownSelectPage';
import {
  useHandleGetQuestionByQuestionnaireStatus,
  useSaveReplyRequestHookQuery
} from 'src/hooks/api/useRiskAssessmentQuestionaire';
import {
  RiskAssessmentQuestionnairePage
} from 'src/pages/RiskAssessmentQuestionnairePage/RiskAssessmentQuestionnairePage';
import { QuestionTypes } from 'src/constans/constans';
import { useProcessingTextToVoiceByType } from 'src/utils/useProcessingTextToVoiceByType';

const QuestionType = {
  [QuestionTypes.SINGLE_SELECT]: SingleSelectPage,
  [QuestionTypes.MULTI_SELECT]: MultiSelectPage,
  [QuestionTypes.USER_INPUT]: UserInputPage,
  [QuestionTypes.DROPDOWN_SELECT]: DropdownSelectPage,
  [QuestionTypes.DEFAULT]: SingleSelectPage,
}

export const RiskAssessmentQuestionnaireHandler = ({
  currentPage,
  currentQuestionnaireStatus,
  botTypePreference,
  familyTreeSettings,
  setFamilyTreeSettings
}) => {
  const chatId = localStorage.getItem('chatId');
  const { data, isSuccess, isFetching } = useHandleGetQuestionByQuestionnaireStatus(chatId, currentPage, currentQuestionnaireStatus);
  const {mutate: saveReplyRequest} = useSaveReplyRequestHookQuery(currentPage);
  const currentQuestionType = data?.question_type || QuestionTypes.DEFAULT;
  const CurrentType = QuestionType[currentQuestionType];
  const { speak } = useProcessingTextToVoiceByType(botTypePreference)

  useEffect(() => {
    if (isSuccess && !isFetching) {
      speak(data)
    }
  }, [isFetching, isSuccess]);

  return (isSuccess && !isFetching ?
    <>
      <RiskAssessmentQuestionnairePage
        questionnaire_progress={data?.questionnaire_progress}
        custom_tags={data?.custom_tags}
        botTypePreference={botTypePreference}
      >
        <CurrentType
          data={data}
          onNext={saveReplyRequest}
          chatId={chatId}
          speechSynthesis={speechSynthesis}
          botTypePreference={botTypePreference}
          familyTreeSettings={familyTreeSettings}
          setFamilyTreeSettings={setFamilyTreeSettings}
        />
      </RiskAssessmentQuestionnairePage>
    </>
    : null
  )
}
